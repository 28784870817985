import { BridgeParams, CreateBridgeTxnResponse } from "@tria-sdk/utils";
import { chainNameToChainId } from "../../utils/constants";
import axios from "axios";

/** returns the txn object to pass to broadcast transaction */
export const createBridgeTxn = async ({
  callData,
  baseUrl = "https://api.production.tria.so",
}: {
  callData: BridgeParams;
  baseUrl?: string;
}): Promise<CreateBridgeTxnResponse> => {
  try {
    const { src, dest } = callData;
    console.log("createBridgeTxn in utils", src, dest);
    // Tria config

    const triaFeeCutResponse = await axios.get(
      `${baseUrl}/api/v2/getPlatformFeeData`
    );
    const tria = {
      enableEstimate: false,
      prependOperatingExpenses: true,
      //To-Do - Add Fee Logic and Test
      affiliateFeePercent: triaFeeCutResponse.data.data.basePathFee,
      affiliateFeeRecipient: triaFeeCutResponse.data.data.vaultAddress,
    };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api.dln.trade/v1.0/dln/order/create-tx",
      params: {
        // src
        srcChainId:
          src.chain?.customChain?.chainId ||
          chainNameToChainId[src.chain?.chainName as string],
        srcChainTokenIn: src.token.address,
        srcChainTokenInAmount: src.token.amountInUnits,
        senderAddress: src.senderAddress,
        srcChainOrderAuthorityAddress: src.senderAddress,

        // dest
        dstChainId: dest.chainId,
        dstChainTokenOut: dest.token.address,
        dstChainTokenOutAmount: dest.token.amountInUnits,
        dstChainTokenOutRecipient: dest.recipientAddress,
        dstChainOrderAuthorityAddress:
          dest.authorityAddress || dest.recipientAddress,
        externalCall: JSON.stringify(dest.externalCall),

        // Tria config
        // affiliateFeePercent: tria.affiliateFeePercent,
        // affiliateFeeRecipient: tria.affiliateFeeRecipient,
        enableEstimate: tria.enableEstimate,
        prependOperatingExpenses: tria.prependOperatingExpenses,
      },
      headers: {
        accept: "application/json",
      },
    };

    console.log(config.params);

    // const { data } = await axios.request(config);
    const { data } = await axios.get(config.url, {
      params: config.params,
      maxBodyLength: config.maxBodyLength,
      // headers: config.headers,
    });

    console.log({ data });

    return { success: true, data };
  } catch (err: any) {
    console.error(err);
    return {
      success: false,
      message: err?.message,
      errorData: err?.response?.data,
    };
  }
};
