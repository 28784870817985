import React, { useEffect, useState } from "react";
import { DisplayCost } from "../../package/ui.common/src/components/Cards/DisplayCostCard/DisplayCostCard";
import { Footer, Nav } from "../../package/ui.common";
import { NftCard } from "../../package/ui.common/src/components/Cards/NftCard";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import { useFetchNfts } from "../../hooks";
import { RootState, updateCurrentRoute } from "../../package/rx.core";
import { useSelector, useDispatch } from "react-redux";
import { updateCurrentNft } from "../../package/rx.core";
import NftAssets from "../../package/ui.common/src/components/LoadersPages/NftAssets";
export default function Nft() {
  const { loading } = useFetchNfts();
  const nftItems = useSelector((store: RootState) => store.User?.userNfts);
  const [triaName, setTriaName] = useState<string>();
  const dispatch = useDispatch();

  dispatch(updateCurrentRoute(window.location.pathname));

  useEffect(() => {
    const storedData = localStorage.getItem("tria.wallet.store.app");
    if (storedData !== null) {
      const parsedData = JSON.parse(storedData);
      setTriaName(parsedData?.triaName);
    } else {
      console.log("Can't find triaName;");
    }
  }, []);
  const navigate = useNavigate();

  const handleNftItemClick = (index: number) => {
    const selected = nftItems[index];
    if (selected) {
      dispatch(
        updateCurrentNft({
          chainName: selected?.chainName,
          tokenAddress: selected?.nftInfo?.contract_address,
          tokenId: selected?.nftInfo?.token_id,
          triaName: triaName || "",
          userAddress: selected?.wallet?.address,
          addressType: selected?.wallet?.type,
          amount: selected?.amount,
          isCustomToken: selected?.isCustomToken,
          type: selected?.type,
        })
      );
    }

    navigate("/nfts/oneNft");
  };

  const [isAccountsOpen, setIsAccountsOpen] = useState(false);
  const [isQrOpen, setIsQrOpen] = useState(false);
  const [isAccountsExpanded, setAccountsExpanded] = useState(false);

  return (
    <div>
      <div className="w-[53vh] h-[100vh] px-[1.4vh] flex items-center justify-center relative">
        <div className="w-[100%] h-[98%] py-[2vh] flex-col justify-center items-center gap-[1.4vh] inline-flex rounded-[1.4vh] font-montserrat ">
          <div className="w-full grow shrink basis-0 flex-col justify-start items-center flex">
            <Nav
              isQrOpen={isQrOpen}
              isAccountsOpen={isAccountsOpen}
              isAccountsExpanded={isAccountsExpanded}
              setIsAccountsOpen={setIsAccountsOpen}
              setIsQrOpen={setIsQrOpen}
              setAccountsExpanded={setAccountsExpanded}
            />
            <DisplayCost
              setIsAccountsOpen={setIsAccountsOpen}
              setIsAccountsExpanded={setAccountsExpanded}
            />
            <div className="w-full h-[56vh]">
              <div className="w-full  h-[8vh] px-[2.4vh] py-[1.4vh] rounded-[2vh] flex-col justify-start items-center gap-[1vh] inline-flex">
                <div className="self-stretch justify-start items-center gap-[2vh] inline-flex pt-[1vh] px-[1vh]">
                  <div className=" h-[2.9vh] justify-start items-center gap-[1vh] flex">
                    <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.4vh] font-semibold leading-normal">
                      Assets
                    </div>
                  </div>
                </div>
              </div>
              {loading && <NftAssets />}
              {!loading && nftItems?.length > 0 && (
                <div className="h-[90%] overflow-y-scroll grid grid-cols-2 scrollbar-thin gap-[2.4vh] px-[2.4vh] scrollbar-thumb-gray-300 scrollbar-track-stone-100 pb-[9.5vh] ">
                  {nftItems?.map((nftItem, index) => (
                    <NftCard
                      key={index}
                      id={index}
                      loading={loading}
                      nftItem={nftItem}
                      onClick={handleNftItemClick}
                    />
                  ))}
                </div>
              )}

              {!loading && !nftItems?.length && (
                <div className="flex flex-col gap-[1vh] items-center justify-center w-full h-[38vh]">
                  <img
                    src="./images/nonftimagedark.svg"
                    className="w-[17.8vh] h-[17.8vh]"
                    alt="nonft"
                  />
                  <p className="font-[600] text-[1.4vh] text-fontLightColor dark:text-fontLightColorDark">
                    You have no NFTs right now
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="absolute bottom-[2vh] visible nft-footer-bg-gradient dark:invisible dark:h-0 left-0 right-0 h-[11vh]">
          <Footer />
        </div>
        <div className="absolute bottom-[2vh] h-0 invisible dark:visible dark:h-[11vh] nft-footer-bg-gradient-dark left-0 right-0">
          <Footer />
        </div>
      </div>
    </div>
  );
}
