import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  AssetForTriaName,
  updateCurrentSendToken,
} from "../../../../../rx.core";

interface Props {
  cryptoItem: AssetForTriaName;
  onClick: (item: AssetForTriaName) => void;
}

export const AssetContainer: React.FC<Props> = ({ cryptoItem, onClick }) => {
  const dispatch = useDispatch();

  // Only run once when component mounts with proper dependency array
  useEffect(() => {
    const payload = {
      tokenAddress: cryptoItem?.tokenAddress || "",
      chainName: cryptoItem?.chainName,
      qouteRate: cryptoItem?.quoteRate,
    };
    dispatch(updateCurrentSendToken(payload));
  }, [
    cryptoItem?.tokenAddress,
    cryptoItem?.chainName,
    cryptoItem?.quoteRate,
    dispatch,
  ]);

  return (
    <div
      className="self-stretch px-[14px] md:px-[20px] py-[8.4px] md:py-[12px] 
        bg-primaryColor dark:bg-primaryDarkColor 
        hover:bg-hoverColor dark:hover:bg-hoverDarkColor
        transition-colors duration-200 ease-in-out
        rounded-[14px] md:rounded-[21px] cursor-pointer
        h-[50px] md:h-[72px]"
      onClick={() => onClick(cryptoItem)}
    >
      <div className="flex items-center justify-between h-full">
        <div className="flex items-center gap-[11.2px] md:gap-[16px] flex-1">
          <div className="relative flex-shrink-0">
            <div className="w-[28px] h-[28px] md:w-[40px] md:h-[40px] rounded-full overflow-hidden">
              {cryptoItem?.logoUrl ? (
                <img
                  className={`w-full h-full object-cover ${
                    cryptoItem?.name?.toUpperCase() === "FUSE"
                      ? "scale-[120%]"
                      : ""
                  }`}
                  src={cryptoItem?.logoUrl}
                  alt={`Logo of: ${cryptoItem?.name}`}
                />
              ) : (
                <div
                  className="w-full h-full bg-violet-600 flex items-center justify-center
                    text-white font-[600] text-[16.8px] md:text-[24px]"
                >
                  {cryptoItem?.name?.charAt(0)}
                </div>
              )}
            </div>

            <div
              className="absolute bottom-[-4px] right-[-4px] 
                w-[15.4px] h-[15.4px] md:w-[22px] md:h-[22px]"
            >
              <img
                className="w-[14px] h-[14px] md:w-[20px] md:h-[20px] rounded-[4px]"
                src={cryptoItem?.chainLogo}
                alt={`Logo of: ${cryptoItem?.chainName}`}
              />
            </div>
          </div>

          <div className="flex flex-col gap-[2.8px] md:gap-[4px] flex-1">
            <div className="flex items-center gap-[0.5vh]">
              <span
                className="text-fontPrimaryColor dark:text-fontPrimaryDarkColor 
                  text-opacity-80 text-[11.2px] md:text-[16px] font-semibold"
              >
                {cryptoItem?.symbol}
              </span>
              {cryptoItem?.wallet?.type === "AA" && (
                <img
                  alt="verified"
                  src="/images/aaverifiedbatch.svg"
                  className="w-[3vh] h-[3vh]"
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col items-end gap-[2.8px] md:gap-[4px]">
          <span
            className="text-fontPrimaryColor dark:text-fontPrimaryDarkColor 
              text-opacity-80 text-[11.2px] md:text-[16px] font-semibold"
          >
            ${cryptoItem?.balanceInUSD?.toFixed(2)}
          </span>
          <span
            className="text-fontLightColor dark:text-fontLightColorDark 
              text-opacity-40 text-[10px] md:text-[14px] font-semibold"
          >
            {(typeof cryptoItem?.balanceInTokens === "string"
              ? parseFloat(cryptoItem?.balanceInTokens)
              : cryptoItem?.balanceInTokens
            )?.toFixed(2)}{" "}
            {cryptoItem?.symbol}
          </span>
        </div>
      </div>
    </div>
  );
};
