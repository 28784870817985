import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserPrefState } from "./userpref.model";
import { CustomTokenDataType } from "../../../types";
import { CustomChainData, CustomNftData } from "@tria-sdk/core";

const INITIAL_STATE: UserPrefState = {};

export const userPrefSlice = createSlice({
  name: "userpref",
  initialState: INITIAL_STATE,
  reducers: {
    updateCurrentEditToken: (
      state,
      action: PayloadAction<CustomTokenDataType>
    ) => {
      state.currentEditToken = action.payload;
    },
    updateCurrentEditNft: (state, action: PayloadAction<CustomNftData>) => {
      state.currentEditNft = action.payload;
    },
    updateCurrentEditChain: (state, action: PayloadAction<CustomChainData>) => {
      state.currentEditChain = action.payload;
    },
  },
});

export const {
  updateCurrentEditChain,
  updateCurrentEditToken,
  updateCurrentEditNft,
} = userPrefSlice.actions;
