import { NetworkNames } from "@etherspot/prime-sdk";
import { explorerBaseUrls } from "@tria-sdk/constants";
import {
  fuse,
  polygon,
  polygonMumbai,
  Chain,
  sepolia,
  optimism,
  arbitrum,
  avalanche,
  mantle,
  bsc,
  base,
} from "viem/chains";
export * from "@tria-sdk/constants";

const chainNameToViemChain = {
  POLYGON: polygon,
  MUMBAI: polygonMumbai,
  FUSE: fuse,
  SEPOLIA: sepolia,
  OPTIMISM: optimism,
  ARBITRUM: arbitrum,
  AVALANCHE: avalanche,
  MANTLE: mantle,
  BINANCE: bsc,
  BASE: base,
};

export const getViemChain = (chainName: string): Chain => {
  //@ts-ignore
  return chainNameToViemChain[chainName];
};

const chainNameToPimlicoChain = {
  POLYGON: "polygon",
  MUMBAI: "mumbai",
  FUSE: "fuse",
  SEPOLIA: "sepolia",
  OPTIMISM: "optimism",
  ARBITRUM: "arbitrum",
  AVALANCHE: "avalanche",
  MANTLE: "mantle",
  BINANCE: "binance",
};

export const getPimlicoChain = (chainName: string) => {
  //@ts-ignore
  return chainNameToPimlicoChain[chainName];
};

export const getExplorerUrl = (chainName: string) => {
  //@ts-ignore
  return explorerBaseUrls[chainName];
};

const chainNameToEtherspotChain = {
  POLYGON: NetworkNames.Polygon,
  // MUMBAI: NetworkNames.Mumbai,
  FUSE: NetworkNames.Fuse,
  SEPOLIA: NetworkNames.Sepolia,
};

export const getEtherspotChain = (chainName: string) => {
  //@ts-ignore
  return chainNameToEtherspotChain[chainName];
};

const erc20PaymasterAddress = {
  usdc: {
    MUMBAI: "",
    SEPOLIA: "0x000000000041F3aFe8892B48D88b6862efe0ec8d",
    POLYGON: "",
    FUSE: "",
  },
};

const erc20Address = {
  usdc: {
    MUMBAI: "",
    SEPOLIA: "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
    POLYGON: "",
    FUSE: "",
  },
};

export const getUsdcAddress = (chainName: string) => {
  //@ts-ignore
  return erc20Address.usdc[chainName];
};

export const getErc20PaymasterAddress = (chainName: string) => {
  //@ts-ignore
  return erc20PaymasterAddress.usdc[chainName];
};
