import { useEffect, useState } from "react";
import {
  Conversation,
  formatDisplayDateAndTime,
  formatNumberDisplay,
} from "../../../../rx.core";

interface Props {
  item: Conversation;
  onClick?: () => void;
}

export const ConversationCard: React.FC<Props> = ({
  item,
  onClick: handleClick,
}) => {
  const [dateTime, setDateTime] = useState(["", ""]);

  useEffect(() => {
    const date = formatDisplayDateAndTime(new Date(item?.timestamp));
    setDateTime(date);
  }, [item]);
  return (
    <>
      <div
        className="flex-col justify-center items-start inline-flex space-y-4"
        onClick={handleClick}
      >
        <div className=" relative ">
          {item?.asset?.logo ? (
            <div className="rounded-[4.76vh] overflow-hidden">
              <img
                className={`w-[4.76vh] h-[4.76vh]  ${
                  item?.asset?.symbol === "FUSE" ? "scale-[120%]" : ""
                }`}
                src={item?.asset?.logo}
              />
            </div>
          ) : (
            <div className="w-[4.76vh] h-[4.76vh] left-0 top-0 absolute rounded-[4.76vh] bg-purple-500 text-white text-[2.4vh] font-[600]">
              {item?.asset?.symbol?.charAt(0)}
            </div>
          )}

          <div className="w-[2.1vh] h-[2.1vh] left-[3.3vh] top-[3.3vh] absolute backdrop-blur-[2.25px] rounded-[0.5vh] justify-center items-center inline-flex z-50 overflow-hidden ">
            <img className="w-[2.1vh] h-[2.1vh]" src={item?.chainLogo} />
          </div>
        </div>
        <div className="flex-col justify-center items-start gap-[0.5vh] flex">
          <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2.8vh] font-semibold font-'Montserrat' leading-tight"></div>
          <div className="text-start text-fontLightColor  dark:text-fontLightColorDark text-[1.67vh] font-semibold leading-tight">
            {formatNumberDisplay(
              item?.value ? parseFloat(item?.value) / 10 ** item?.decimals : 0,
              6
            )}{" "}
            {item?.asset?.symbol}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-start">
        <div className="justify-center items-end gap-[0.5vh] inline-flex">
          <div className="text-end w-[7.1vh] text-fontLightColor dark:text-fontLightColorDark text-[1.2vh] font-semibold font-'Montserrat' leading-tight">
            {dateTime[1]}
          </div>
          <div className={`justify-center items-end gap-[0.5vh] flex`}>
            <div
              className={`text-center ${
                item?.tag == "Received"
                  ? "text-greenWarning"
                  : item?.tag == "Sent"
                  ? "text-greenWarning"
                  : "text-fontLightColor dark:text-fontLightColorDark"
              }  text-[1.2vh] font-bold font-'Montserrat' leading-tight`}
            >
              •
            </div>
            <div
              className={`text-center text-green-400 text-[1.2vh] font-semibold leading-tight`}
            >
              {item?.tag}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
