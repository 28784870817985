import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  RootState,
  updateAppState,
  updateCustomChain,
  updateUserAddressOnChain,
  updateUserChain,
} from "../package/rx.core";

import { TriaWalletStore } from "../package/rx.core/src/utils/getWalletStoreLocalStorage";
import { isEvmChain } from "@tria-sdk/constants";

const paramInitialState: TriaWalletStore = {};

export const useHandleParams = (values: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const route = useSelector((store: RootState) => store?.Route?.currentRoute);
  const navigate = useNavigate();

  const params: TriaWalletStore = useMemo(() => {
    const encodedParams = values;
    if (encodedParams) {
      setIsLoading(true);
      const jsonString = atob(encodedParams);
      let jsonData;
      if (jsonString) {
        jsonData = JSON.parse(jsonString);
      }
      return jsonData as TriaWalletStore;
    }
    return paramInitialState;
  }, [values]);

  useEffect(() => {
    if (params.triaName) {
      const appState = JSON.stringify(params);
      localStorage.setItem(`tria.wallet.store.app`, appState);

      const chain = params?.defaultChain || "FUSE";
      let address: string | undefined = "";
      let aa: string | undefined = "";
      if (isEvmChain(chain)) {
        address = params.addresses?.evm.address;
        aa = params.addresses?.aa.address;
      } else {
        address = params.addresses?.nonEvm.find(
          (el) => el.chainName === chain
        )?.address;
      }
      dispatch(updateUserChain(chain));
      dispatch(
        updateUserAddressOnChain({
          triaName: params?.triaName,
          type: "AA",
          chainName: params?.defaultChain || "",
          address,
          aa,
        })
      );
      console.log("dark mode setting", params?.darkMode);
      if (params?.darkMode !== null) {
        if (params?.darkMode) {
          document.documentElement.classList.add("dark");
          localStorage.setItem("theme", "dark");
        } else {
          document.documentElement.classList.remove("dark");
          localStorage.setItem("theme", "light");
        }
      }
      localStorage.setItem("tria.wallet.params", values);
      if (params.liteMode) {
        navigate("/lite");
      } else {
        navigate(route);
      }
      if (params.embedded) {
        localStorage.setItem("embedded", "true");
      } else {
        localStorage.setItem("embedded", "false");
      }
      if (params.customChain) {
        dispatch(updateCustomChain(params.customChain));
      }
      setIsLoading(false);
    }
  }, [params]);

  return { isLoading, liteMode: params.liteMode, embedded: params.embedded };
};
