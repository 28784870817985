import React, { useEffect, useState } from "react";
import { Nav, Opentria, useTriaUser } from "../../package/ui.common";
import { BackButton } from "../../package/ui.common/src/components/Buttons/BackButton";
import { useNavigate } from "react-router-dom";
import { CustomTokenDataType, useToast } from "../../package/rx.core";
import ChainSelectionDropdown from "../../package/ui.common/src/components/Dropdowns/ChainSelectionDropDown";
import { useReload } from "../../package/ui.common/src/contexts/reload/ReloadContext";
const initialTokenData = {
  chainId: 0,
  tokenName: "",
  tokenSymbol: "",
  tokenAddress: "",
  decimals: 0,
};
const AddCustomToken = () => {
  const [customToken, setCustomToken] =
    useState<CustomTokenDataType>(initialTokenData);
  const [selectedChainId, setSelectedChainId] = useState<number>();
  const { addCustomToken: CustomTokenAdd } = useTriaUser();
  const navigate = useNavigate();
  const { setToastData, openToastMessage, closeToastMessage } = useToast();
  const HandleClick = () => {
    navigate("/customtoken");
  };
  const { toggleReload } = useReload();
  const handleContractAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newName = event.target.value?.trim();
    setCustomToken((prevCustomToken) => ({
      ...prevCustomToken,
      tokenAddress: newName,
    }));
  };

  const handleTokenSymbolChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value?.trim();
    setCustomToken((prevCustomToken) => ({
      ...prevCustomToken,
      tokenSymbol: value,
    }));
  };
  const handleTokenNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value?.trim();
    setCustomToken((prevCustomToken) => ({
      ...prevCustomToken,
      tokenName: value,
    }));
  };
  const handleTokenDecimalChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value?.trim();
    setCustomToken((prevCustomToken) => ({
      ...prevCustomToken,
      decimals: parseFloat(value),
    }));
  };
  const handleTokenUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value?.trim();
    setCustomToken((prevCustomToken) => ({
      ...prevCustomToken,
      tokenUrl: value,
    }));
  };
  useEffect(() => {
    if (selectedChainId) {
      setCustomToken((prevCustomToken) => ({
        ...prevCustomToken,
        chainId: selectedChainId,
      }));
    }
  }, [selectedChainId]);
  const addCustomToken = async () => {
    const accessToken = localStorage.getItem("tria.accessToken");
    try {
      const response = await CustomTokenAdd(accessToken || "", {
        tokenSymbol: customToken?.tokenSymbol,
        tokenAddress: customToken?.tokenAddress || "",
        chainId: customToken?.chainId,
        decimals: customToken?.decimals,
        tokenName: customToken?.tokenName || customToken?.tokenSymbol,
      });
      if (response?.success) {
        setToastData({ title: "Token Added Successfully", status: "message" });
        openToastMessage();
        // setIsCopied(false)
        await setTimeout(() => closeToastMessage(), 4000);
        toggleReload(true);
        navigate("/home");
      } else {
        setToastData({ title: response?.message, status: "error" });
        openToastMessage();
        // setIsCopied(false)
        setTimeout(() => closeToastMessage(), 4000);
      }
    } catch (err) {
      setToastData({ title: `${err}`, status: "error" });
      openToastMessage();
      // setIsCopied(false)
      setTimeout(() => closeToastMessage(), 4000);
    }
  };
  return (
    <div>
      <div className="flex w-[53vh] h-[100vh] px-[1.4vh] items-center justify-center relative  bg-primaryColor dark:bg-primaryDarkColor ">
        <div className="w-[100%] h-[98%] py-[2vh] flex-col justify-center items-center gap-[1.4vh] inline-flex  rounded-xl font-montserrat">
          <div className="w-full grow shrink basis-0 flex-col justify-start items-center flex">
            <Nav />
            {/* <Toast /> */}
            <div className="w-full h-[6.4vh] px-[2vh] py-[1.67vh] rounded-[2.4vh] flex-col justify-start items-center gap-[1.4vh] inline-flex">
              <div className="self-stretch justify-start items-start gap-[1.6vh] flex flex-col ">
                <div className="h-[2.8vh] justify-start items-center gap-[1vh] flex">
                  <div className="w-[2vh] h-[2vh] justify-center items-center flex">
                    <div className="w-[1.67vh] h-[1.67vh] relative">
                      <BackButton onClick={HandleClick} />
                    </div>
                  </div>
                  <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-[2.4vh] font-semibold leading-normal">
                    Add Custom Token
                  </div>
                </div>
                <p className="text-center text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-tight font-semibold tracking-tight ml-[1.5vh]">
                  Add a token manually
                </p>
              </div>
            </div>

            <div className="flex flex-col h-[60vh] overflow-y-scroll mt-[8vh] w-full px-[2vh] gap-[4vh]">
              <div className="w-full gap-[1vh] flex flex-col">
                <p className="text-start text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-[120%] font-semibold tracking-tight ">
                  Token Contract Address
                </p>
                <input
                  placeholder=""
                  className="py-[1vh] px-[2.4vh] rounded-[2.4vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[400] border-[1px] border-hoverColor dark:border-hoverDarkColor dark:bg-hoverDarkColor bg-hoverColor focus:outline-none focus:border-[1px] focus:border-outlining dark:focus:border-outliningDark"
                  onChange={handleContractAddressChange}
                />
              </div>
              <div className="w-full gap-[1vh] flex flex-col">
                <p className="text-start text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-[120%] font-semibold tracking-tight ">
                  Chain
                </p>
                <ChainSelectionDropdown
                  setSelectedChainId={setSelectedChainId}
                />
              </div>
              <div className="w-full gap-[1vh] flex flex-col">
                <p className="text-start text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-[120%] font-semibold tracking-tight ">
                  Token Name
                </p>
                <input
                  placeholder=""
                  className="py-[1vh] px-[2.4vh] rounded-[2.4vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[400] border-[1px] border-hoverColor dark:border-hoverDarkColor dark:bg-hoverDarkColor bg-hoverColor focus:outline-none focus:border-[1px] focus:border-outlining dark:focus:border-outliningDark"
                  onChange={handleTokenNameChange}
                />
              </div>
              <div className="w-full gap-[1vh] flex flex-col">
                <p className="text-start text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-[120%] font-semibold tracking-tight ">
                  Token Symbol
                </p>
                <input
                  placeholder=""
                  className="py-[1vh] px-[2.4vh] rounded-[2.4vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[400] border-[1px] border-hoverColor dark:border-hoverDarkColor dark:bg-hoverDarkColor bg-hoverColor focus:outline-none focus:border-[1px] focus:border-outlining dark:focus:border-outliningDark"
                  onChange={handleTokenSymbolChange}
                />
              </div>
              <div className="w-full gap-[1vh] flex flex-col">
                <p className="text-start text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-[120%] font-semibold tracking-tight ">
                  Token Decimal
                </p>
                <input
                  placeholder=""
                  className="py-[1vh] px-[2.4vh] rounded-[2.4vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[400] border-[1px] border-hoverColor dark:border-hoverDarkColor dark:bg-hoverDarkColor bg-hoverColor focus:outline-none focus:border-[1px] focus:border-outlining dark:focus:border-outliningDark"
                  onChange={handleTokenDecimalChange}
                />
              </div>
              <div className="w-full gap-[1vh] flex flex-col">
                <p className="text-start text-fontLightColor dark:text-fontLightColorDark text-opacity-60 text-[1.67vh]   leading-[120%] font-semibold tracking-tight ">
                  Token Url (Optional)
                </p>
                <input
                  placeholder=""
                  className="py-[1vh] px-[2.4vh] rounded-[2.4vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2vh] font-[400] border-[1px] border-hoverColor dark:border-hoverDarkColor dark:bg-hoverDarkColor bg-hoverColor focus:outline-none focus:border-[1px] focus:border-outlining dark:focus:border-outliningDark"
                  onChange={handleTokenUrlChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-[2vh] left-0 right-0 h-[11vh] w-full flex items-start justify-center py-[2vh]">
        {/* <Footer currentActive='/home' /> */}
        <button
          className="bg-primaryDarkColor dark:bg-primaryColor py-[1.4vh] px-[2.4vh] w-[50vh] rounded-[3vh] text-[2vh] font-semibold leading-[120%] "
          onClick={addCustomToken}
        >
          Save
        </button>
      </div>
      <div className="absolute bottom-[0vh] invisible dark:visible  flex items-center justify-center left-0 right-0 w-full ">
        {/* <Footer currentActive='/home' /> */}
        <Opentria />
      </div>
    </div>
  );
};

export default AddCustomToken;
