import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ChainAddressData,
  NetworkItem,
  RootState,
  copyToClipboard,
  formatAddress,
  useToast,
} from "../../../../rx.core";
import { CopyButton } from "../Buttons";
import { QrButton } from "../Buttons/QrButton";
import QR from "./QR";
import { chainNameToLogo } from "@tria-sdk/constants";
import { useUserPrefs } from "../../../../../hooks";
import { useNavigate } from "react-router-dom";
const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const ChainLogo: React.FC<{ chainName: string; logoUrl?: string }> = ({
  chainName,
  logoUrl,
}) => {
  if (logoUrl) {
    return (
      <img
        src={logoUrl}
        alt={chainName}
        className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
      />
    );
  }

  // Get first letter or first two letters if no space
  const initials = chainName
    .split(" ")
    .slice(0, 2)
    .map((word) => word[0])
    .join("")
    .toUpperCase();

  return (
    <div className="w-[2.8vh] h-[2.8vh] flex justify-center items-center text-[1.2vh] border-1 bg-[#a855f7] text-primaryColor rounded-[0.6vh]">
      {initials}
    </div>
  );
};

interface Props {
  userAddresses: ChainAddressData[];
  userNonEvmAddresses: ChainAddressData[];
  supportedChains: string[];
  priorityChains?: string[];
  lite?: boolean;
  qrHandler?: (value: boolean) => void;
  setAccountsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  accountsExpanded: boolean;
  evmAddress?: string;
}

export const Accounts: React.FC<Props> = ({
  userAddresses,
  userNonEvmAddresses,
  supportedChains,
  priorityChains = ["ETH", "SOLANA", "BINANCE"],
  lite = false,
  qrHandler,
  accountsExpanded,
  setAccountsExpanded,
  evmAddress,
}) => {
  const [showQr, setShowQr] = useState(false);
  const [qrData, setQrData] = useState({
    address: "",
    chain: undefined as NetworkItem | undefined,
  });
  const [copied, setCopied] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();

  const { setToastData, openToastMessage, closeToastMessage } = useToast();
  const networkItems = useSelector(
    (store: RootState) => store.Constants?.NetworkItems
  );
  const customChain = useSelector(
    (store: RootState) => store.AppState.AppCurrentState.customChain
  );
  console.log("accounts custom chain redux", customChain);
  const { data: userPrefs, refetch: refetchUserPrefs } = useUserPrefs();

  const handleAddCustomChain = () => {
    if (customChain) {
      const approveParams = btoa(
        JSON.stringify({
          customChain: {
            chainId: customChain.chainId,
            chainName: customChain.chainName,
            rpcUrl: customChain.rpcUrl,
            currencySymbol: customChain.currencySymbol,
            currencyName: customChain.currencyName,
            chainLogo: customChain.chainLogo,
            nativeTokenLogo: customChain.nativeTokenLogo,
          },
        })
      );
      navigate(`/approvecustomchain/${approveParams}?lite=${lite}`);
    }
  };

  const allChains = useMemo(() => {
    const combinedAddresses = [...userAddresses, ...userNonEvmAddresses];
    let chains = supportedChains
      .map((chainName) => {
        const chainData = combinedAddresses.find(
          (addr) => addr.chainName.toUpperCase() === chainName.toUpperCase()
        );

        if (!chainData) return null;

        return {
          ...chainData,
          logo: chainNameToLogo[chainName] || "",
          isCustomChain: false,
        };
      })
      .filter(Boolean) as ChainAddressData[];
    console.log("accounts custom chain 2", customChain, evmAddress);
    // Add custom chain if it exists
    if (customChain && evmAddress) {
      const customChainData: ChainAddressData = {
        id: customChain.chainId.toString(),
        chainName: customChain.chainName,
        address: evmAddress,
        isAA: false,
        isEvm: true,
        logo: customChain.chainLogo || "",
        isCustomChain: true,
      };

      chains = [customChainData, ...chains];
    }

    return chains;
  }, [
    supportedChains,
    userAddresses,
    userNonEvmAddresses,
    customChain,
    evmAddress,
  ]);
  console.log("accounts all chains", allChains);
  const sortedChains = useMemo(() => {
    const priorityMap = new Map(
      priorityChains.map((chain, index) => [chain.toUpperCase(), index])
    );

    const prioritized = priorityChains
      .map((chainName) =>
        allChains.find(
          (chain) => chain.chainName.toUpperCase() === chainName.toUpperCase()
        )
      )
      .filter(Boolean) as (ChainAddressData & { logo: string })[];

    const rest = allChains.filter(
      (chain) => !priorityMap.has(chain.chainName.toUpperCase())
    );

    return [...prioritized, ...rest];
  }, [allChains, priorityChains]);
  console.log("accounts sorted chains", sortedChains);
  const displayedChains = useMemo(() => {
    // If searching, include custom chain in regular search
    if (searchTerm) {
      return sortedChains.filter((chain) =>
        chain.chainName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Not searching
    if (!accountsExpanded) {
      // Get priority chains (excluding custom chain)
      const regularPriorityChains = sortedChains
        .filter((chain) => !chain.isCustomChain)
        .slice(0, priorityChains.length);

      // Find custom chain if it exists
      const customChainItem = sortedChains.find((chain) => chain.isCustomChain);

      // If we have a custom chain, put it at the top
      if (customChainItem) {
        return [
          customChainItem,
          ...regularPriorityChains.slice(0, priorityChains.length - 1),
        ];
      }

      return regularPriorityChains;
    }

    // Show all chains with custom chain at top if it exists
    const customChain = sortedChains.find((chain) => chain.isCustomChain);
    const regularChains = sortedChains.filter((chain) => !chain.isCustomChain);

    return customChain ? [customChain, ...regularChains] : regularChains;
  }, [sortedChains, accountsExpanded, searchTerm, priorityChains]);
  console.log("accounts displayed chains", displayedChains);
  useEffect(() => {
    if (sortedChains.length > priorityChains.length) {
      setShowSearch(true);
    }
  }, [sortedChains, priorityChains]);

  const handleCopyClick = (address: string) => {
    const { copied, text } = copyToClipboard(address);
    setCopied(copied);
    if (copied) {
      setToastData({ title: "Copied!", desc: text, status: "message" });
      openToastMessage();
      setTimeout(() => {
        closeToastMessage();
        setCopied(false);
      }, 4000);
    }
  };

  const handleQrClick = (address: string, chain: NetworkItem | undefined) => {
    setShowQr(!showQr);
    setQrData({ address, chain });
    qrHandler && qrHandler(!showQr);
  };
  console.log("accounts user prefs", userPrefs);

  const renderChainItem = (chain: ChainAddressData) => {
    const isApproved =
      !chain.isCustomChain ||
      userPrefs?.customChains?.some((c) => c.chainId.toString() === chain.id);
    console.log("accounts chain is approved", isApproved);
    return (
      <div
        key={chain.id}
        className="self-stretch justify-start items-center gap-[2vh] inline-flex"
        style={{
          background:
            chain.isCustomChain && !isApproved
              ? "rgba(60, 56, 44, 0.6)"
              : "transparent",
          borderRadius: "8px",
          padding: "8px",
        }}
      >
        <div className="flex gap-[0.5vh] items-center">
          <ChainLogo chainName={chain.chainName} logoUrl={chain.logo} />
        </div>
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex">
          <div className="flex items-center">
            <div
              className={`${
                lite ? "text-sm" : "text-[2vh]"
              } text-left text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 font-semibold leading-tight`}
            >
              {chain.chainName}
            </div>
            {chain.isAA && (
              <span className="px-1 py-0.5 rounded">
                <img src="/icons/smart-wallet.svg" className="w-4 h-4" />
              </span>
            )}
          </div>
          <div
            className={`${
              lite ? "text-xs" : "text-[1.67vh]"
            } text-center text-neutral-400 font-semibold leading-tight max-w-[24vh] overflow-hidden`}
          >
            {formatAddress(chain.address)}
          </div>
        </div>
        {chain.isCustomChain && !isApproved ? (
          <button
            onClick={handleAddCustomChain}
            style={{ border: "0.2px solid rgba(255, 255, 255, 0.2)" }}
            className="px-3 py-1 rounded-full bg-primary text-white text-xxs font-semibold"
          >
            Add
          </button>
        ) : (
          <>
            <QrButton
              onClick={() =>
                handleQrClick(
                  chain.address,
                  networkItems.find(
                    (item) => item.chainName === chain.chainName
                  )
                )
              }
            />
            <CopyButton
              onClick={() => handleCopyClick(chain.address)}
              height="2.1vh"
              width="2.1vh"
            />
          </>
        )}
      </div>
    );
  };
  return (
    <div
      className={`min-w-full bg-primaryColor ${
        showQr ? "transform duration-200" : "h-[unset]"
      } rounded-lg ${
        lite
          ? "dark:bg-popoverBackgroundDark"
          : "dark:bg-primaryDarkColor max-h-[62.5vh]"
      }`}
    >
      {!showQr && showSearch && (
        <div className="px-4 pb-2 pt-4">
          <div className="text-sm text-black dark:text-white w-full text-center justify-center mb-4 font-semibold">
            All Networks
          </div>
          <input
            type="text"
            placeholder="Search"
            className="dark:text-black text-white text-sm w-full h-[4vh] rounded-full px-3 mb-4 bg-black/10 dark:bg-primaryColor dark:bg-hoverDarkColor focus:outline-none focus:border focus:border-neutral-600"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}

      {!showQr && (
        <>
          <div
            className={`max-h-[250px] overflow-y-scroll pl-2 pr-1 pt-4 flex-col justify-start gap-2 inline-flex min-w-full relative`}
          >
            {displayedChains.map(renderChainItem)}

            {copied && (
              <div className="absolute bg-primaryColor dark:bg-primaryDarkColor text-fontLightColor dark:text-fontLightColorDark flex items-center justify-center rounded-[25px] w-[8.3vh] h-[2.8vh] bottom-[-1.2vh] right-[1.2vh] text-[1.67vh]">
                Copied
              </div>
            )}
          </div>
          {sortedChains.length > priorityChains.length && !searchTerm && (
            <div
              className="text-sm w-full flex justify-center items-center px-2 pb-3 text-white/80 underline cursor-pointer"
              onClick={() => setAccountsExpanded(!accountsExpanded)}
            >
              {accountsExpanded ? "" : "Show more"}
            </div>
          )}
        </>
      )}

      {showQr && (
        <div
          className={`${
            lite ? "dark:bg-popoverBackgroundDark" : "dark:bg-primaryDarkColor"
          } shadow-2xl flex bg-primaryColor dark:bg-popoverBackgroundDark rounded-[2vh] z-50`}
        >
          <QR
            item={qrData.address}
            activeChain={qrData.chain}
            networkItems={allChains as (ChainAddressData & { logo: string })[]}
            logo={qrData.chain?.logo || ""}
            liteMode={lite}
          />
        </div>
      )}
    </div>
  );
};

export default Accounts;
