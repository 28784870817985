export enum LayoutElement {
  EMAIL_PHONE = "email-phone",
  WEB2 = "web2",
  DIVIDER = "divider",
  WEB3 = "web3",
}

export enum EmailPhoneMethod {
  EMAIL = "email",
  PHONE = "phone",
}
