import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CustomTokenDataType,
  RootState,
  useToast,
} from "../../package/rx.core";
import { useTriaUser } from "../../package/ui.common";
import { BackgroundGrid } from "../../package/ui.common/src/components/Backgrounds/BackgroundGrid";
import { useSelector } from "react-redux";
import { CustomChainData } from "@tria-sdk/core";
import { useReload } from "../../package/ui.common/src/contexts/reload/ReloadContext";

interface ApproveCustomChainParam {
  customChain: CustomChainData;
}
const ApproveCustomChain: React.FC = () => {
  const [params, setParams] = useState<ApproveCustomChainParam>();
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const { params: routeParams } = useParams();
  const { addCustomChain: CustomChainAdd, addCustomToken: CustomTokenAdd } =
    useTriaUser();
  const { setToastData, openToastMessage, closeToastMessage } = useToast();
  const darkMode = useSelector(
    (store: RootState) => store.AppState.AppCurrentState.darkMode
  );
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isLite = queryParams.get("lite") === "true";
  const { toggleReload } = useReload();
  console.log("isLite approval page", isLite);
  useEffect(() => {
    if (routeParams) {
      try {
        const decodedParams = JSON.parse(
          atob(routeParams)
        ) as ApproveCustomChainParam;
        setParams(decodedParams);
      } catch (err) {
        setError("Invalid parameters");
        console.error("Error parsing params:", err);
      }
    }
  }, [routeParams]);
  const handleApprove = async () => {
    if (!params?.customChain || isLoading) return;
    const customChain = params.customChain;

    setIsLoading(true);
    const accessToken = localStorage.getItem("tria.accessToken");
    try {
      const response = await CustomChainAdd(accessToken || "", customChain);

      // Add native token
      const customToken: CustomTokenDataType = {
        chainId: customChain.chainId,
        tokenName: customChain.currencyName,
        tokenSymbol: customChain.currencySymbol,
        tokenAddress: "",
        decimals: 18,
        tokenUrl: customChain.nativeTokenLogo,
      };

      await CustomTokenAdd(accessToken || "", customToken);

      if (response?.success) {
        setToastData({ title: "Chain added successfully", status: "success" });
        openToastMessage();
        setTimeout(() => closeToastMessage(), 4000);
        toggleReload(true);
        if (isLite) {
          navigate(`/lite`);
        } else {
          navigate("/home");
        }
      } else {
        setError(response?.message);
        setToastData({ title: response?.message, status: "error" });
        openToastMessage();
        setTimeout(() => closeToastMessage(), 4000);
      }
    } catch (err) {
      setError(String(err));
      setToastData({ title: String(err), status: "error" });
      openToastMessage();
      setTimeout(() => closeToastMessage(), 4000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    if (isLite) {
      navigate(`/home?lite=true`);
    } else {
      navigate("/home");
    }
  };

  if (!params?.customChain) return null;

  return (
    <div
      className={`${
        isLite ? "w-[100vw] dark:bg-primaryDarkColorLite" : "w-[53vh]"
      } h-[100vh] p-[2vh] flex-col justify-between items-center inline-flex rounded-[1.4vh] relative`}
    >
      <div className="h-screen flex flex-col relative pt-[40px] w-full gap-8">
        <div className="w-full justify-end items-start inline-flex mt-2 mr-4">
          <div className="absolute left-[-52px] top-[-52px]">
            <BackgroundGrid color={"#808080"} />
          </div>
        </div>

        <div className="w-full py-0 -mt-5 flex-col justify-center items-center gap-4 flex">
          <div className="self-stretch h-[98px] py-3 flex-col justify-center items-start gap-4 flex">
            <div className="self-stretch justify-center items-center gap-2 inline-flex">
              <div
                className="text-center text-fontLightColor text-[10px] font-semibold font-montserrat leading-normal dark:text-fontLightColorDark border-[1px] border-outlining dark:border-outliningDark py-[10px] px-[20px] rounded-[20px]"
                style={{ color: "rgba(128, 128, 128, 1)" }}
              >
                {params?.customChain.chainName}
              </div>
            </div>
            <div className="w-full mt-8 justify-start items-center inline-flex">
              <div className="grow shrink basis-0 text-center text-fontLightColor dark:text-fontLightColorDark text-[9px] font-normal font-montserrat leading-[16.80px] flex flex-col gap-4">
                <p className="text-[16px] font-semibold text-fontPrimaryColor dark:text-fontPrimaryDarkColor">
                  Allow this site to add a network?
                </p>
                <div className="flex flex-col">
                  <p className="text-center text-fontLightColor dark:text-fontLightColorDark text-[9px] font-normal font-montserrat leading-[16.80px]">
                    This will allow the network to work in Tria wallet.
                  </p>
                  <p className="text-center text-fontLightColor dark:text-fontLightColorDark text-[9px] font-semibold font-montserrat leading-[16.80px]">
                    Tria does not verify custom networks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-col w-full justify-between items-center gap-2 flex h-1/2">
          <div className="self-stretch grow shrink basis-0 flex-col justify-start items-center gap-2 flex">
            <div className="rounded-2xl border-2 border-violet-400 border-opacity-30 w-full h-auto">
              <div className="text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-90 text-xs font-normal font-montserrat p-2">
                <p className="font-semibold text-sm my-1">Network name</p>
                <p className="font-normal text-[12px] my-1">
                  {params?.customChain.chainName}
                </p>
                <p className="font-semibold text-sm my-1">Network URL</p>
                <p className="font-normal my-1 overflow-clip">
                  {params?.customChain.rpcUrl}
                </p>
                <p className="font-semibold text-sm my-1">Chain ID</p>
                <p className="font-normal my-1">
                  {params?.customChain.chainId}
                </p>
                <p className="font-semibold text-sm my-1">Currency Symbol</p>
                <p className="font-normal my-1">
                  {params?.customChain.currencySymbol}
                </p>
              </div>
            </div>
          </div>
          <div className="h-16 -mt-5">
            {error ? (
              <div className="text-red-500 text-sm text-center">{error}</div>
            ) : null}
          </div>
        </div>

        <div className="w-full absolute bottom-0 left-0 right-0 flex justify-between gap-4 py-4">
          <button
            onClick={handleCancel}
            className="text-sm w-full px-8 py-2 rounded-full bg-secondaryDarkColorLite text-fontPrimaryColor dark:text-fontPrimaryDarkColor font-semibold"
          >
            Reject
          </button>
          <button
            onClick={handleApprove}
            disabled={isLoading}
            className={`text-sm w-full px-8 py-2 rounded-full bg-primaryDarkColor dark:bg-primaryColor dark:text-black text-fontPrimaryDarkColor font-semibold flex justify-center items-center ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isLoading ? (
              <div className="flex items-center gap-2">
                <div className="w-4 h-4 border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                <span>Adding...</span>
              </div>
            ) : (
              "Approve"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApproveCustomChain;
