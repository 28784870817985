import React, { useEffect, useState } from "react";
import {
  AvatarItem,
  HistoryItem,
  formatAddress,
  useToast,
} from "../../../../../../rx.core";
import { UserAvatar } from "../../user/UserAvatar";
import { useResolveTriaName } from "../../../../../../../hooks";
import { AvatarContainer } from "../../user/AvatarContainer";

type Props = {
  transaction: HistoryItem;
};

export const NftTransactionDetails: React.FC<Props> = ({ transaction }) => {
  const [avatar, setAvatar] = useState<AvatarItem>();
  const [isCopied] = useState<{
    status: boolean;
    value: string;
  }>();
  const { openToastMessage, setToastData, closeToastMessage } = useToast();
  const receivedNameQuery = useResolveTriaName(
    transaction?.desc?.action === "Received" ? transaction.chainName : "",
    transaction?.desc?.action === "Received" ? transaction.from?.address : ""
  );

  const sentNameQuery = useResolveTriaName(
    transaction?.desc?.action === "Sent" ? transaction.chainName : "",
    transaction?.desc?.action === "Sent" ? transaction.to?.address : ""
  );

  const triaName =
    transaction?.desc?.action === "Received"
      ? receivedNameQuery.data
      : sentNameQuery.data;

  useEffect(() => {
    const fetchAvatar = async () => {
      if (triaName) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SDK_BASE_URL}/api/v1/user/getAvatarByTriaName?triaNames=${triaName}`
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();

          if (
            data &&
            data.response &&
            data.response[triaName] &&
            data.response[triaName]?.length > 0
          ) {
            const userData = data.response[triaName][0];
            if (userData?.avatar && userData?.background) {
              setAvatar({
                avatar: userData?.avatar,
                background: userData?.background,
              });
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchAvatar();
  }, [triaName]);

  useEffect(() => {
    if (isCopied?.status) {
      setToastData({
        status: "message",
        title: "Copied!",
        desc: isCopied?.value,
      });
      openToastMessage();
      setTimeout(closeToastMessage, 4000);
    }
  }, [closeToastMessage, isCopied, openToastMessage, setToastData]);
  return (
    <div className="w-full px-[2.4vh] py-[2vh] rounded-[2vh] border-[0.2vh]  border-blue  border-opacity-30 justify-center items-center gap-[2.85vh] inline-flex">
      <div className="w-[17.8vh] p-[0.7vh] bg-primaryColor dark:bg-primaryDarkColor rounded-[1vh] shadow border-2 border-neutral-50 flex-col justify-center items-center gap-[0.7vh] inline-flex">
        <div className="w-[16.2vh] h-[18.17vh] relative">
          <img
            className="w-[16.2vh] h-[18.17vh] left-0 top-0 absolute rounded"
            src={transaction?.desc?.assetLogo || ""}
            alt="asset"
          />
          <div className="w-[2.4vh] h-[2.4vh] left-[0.6vh] top-[0.7vh] absolute shadow justify-start items-center gap-[0.6vh] inline-flex">
            <div className="w-[2.4vh] h-[2.4vh] pl-0.5 pr-[2.44px] pt-0.5 pb-[2.45px] bg-primaryColor dark:bg-primaryDarkColor rounded-[2.4vh] border border-white backdrop-blur-[2.45px] flex-col justify-center items-center inline-flex">
              <img
                alt="chainlogo"
                className="w-[1.78vh] h-[1.78vh]"
                src={transaction?.chainLogo}
              />
            </div>
          </div>
        </div>
        <div className="self-stretch px-[0.6vh] py-[0.7vh] rounded-[1.3vh] justify-start items-center gap-[0.7vh] inline-flex">
          <div className="grow shrink basis-0 h-[1.67vh] justify-between items-center flex">
            <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-xs font-semibold  leading-[13.70px]">
              {transaction?.desc?.assetSymbol}
            </div>
            <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.4vh] font-semibold  leading-tight">
              {transaction?.desc?.value}
            </div>
          </div>
        </div>
      </div>
      <div className="grow shrink basis-0 flex-col justify-center items-start inline-flex">
        <div className="self-stretch py-[1vh] justify-start items-center gap-[1.4vh] inline-flex">
          {transaction?.desc?.action === "Sent" ? (
            <UserAvatar width="6vh" height="6vh" rounded="3vh" />
          ) : (
            <>
              {!avatar ? (
                <img
                  alt="user"
                  className="w-[6vh] h-[6vh] rounded-[3vh]"
                  src="/icons/user2.svg"
                />
              ) : (
                <AvatarContainer
                  avatar={avatar?.avatar}
                  background={avatar?.background}
                  height="6vh"
                  width="6vh"
                  borderRadius="3vh"
                />
              )}
            </>
          )}
          {transaction?.desc?.action === "Sent" ? (
            <div className="px-[1vh] justify-start items-center flex">
              <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-semibold  leading-tight flex flex-col">
                <p className="flex items-center justify-center gap-[0.5vh]">
                  {" "}
                  You
                  {transaction?.wallet?.type === "AA" && (
                    <img src="/images/aaverifiedbatch.svg" alt="veri" />
                  )}
                </p>
                <p>({formatAddress(transaction?.wallet?.address)})</p>
              </div>
            </div>
          ) : (
            <div className="px-[1vh] justify-start items-center flex">
              <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-semibold  leading-tight">
                {" "}
                {!triaName
                  ? formatAddress(transaction?.from?.address)
                  : triaName}
              </div>
            </div>
          )}
        </div>
        <div className="self-stretch px-[1.4vh] py-[0.5vh] justify-start items-center gap-[1.4vh] inline-flex">
          <div className="w-[2.8vh] h-[2.8vh] relative">
            <div className="w-[2.8vh] h-[2.8vh] left-0 top-0 absolute">
              <img alt="arrow" src="/icons/arrow-down.png"></img>
            </div>
          </div>
        </div>
        <div className="self-stretch py-[1vh] justify-start items-center gap-[1.4vh] inline-flex">
          {transaction?.desc?.action === "Sent" ? (
            <>
              {!avatar ? (
                <img
                  alt="user"
                  className="w-[6vh] h-[6vh] rounded-[3vh]"
                  src="/icons/user2.svg"
                />
              ) : (
                <AvatarContainer
                  avatar={avatar?.avatar}
                  background={avatar?.background}
                  height="6vh"
                  width="6vh"
                  borderRadius="3vh"
                />
              )}
            </>
          ) : (
            <UserAvatar width="6vh" height="6vh" rounded="3vh" />
          )}
          <div className="px-[1vh] justify-start items-center flex">
            {transaction?.desc?.action === "Sent" ? (
              <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-semibold  leading-tight">
                {" "}
                {!triaName ? formatAddress(transaction?.to?.address) : triaName}
              </div>
            ) : (
              <div className="text-center text-fontLightColor dark:text-fontLightColorDark text-[1.67vh] font-semibold  leading-tight flex flex-col">
                <p className="flex items-center justify-center gap-[0.5vh]">
                  {" "}
                  You
                  {transaction?.wallet?.type === "AA" && (
                    <img src="/images/aaverifiedbatch.svg" alt="veri" />
                  )}
                </p>
                <p>({transaction?.wallet?.address})</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
