import { useQuery } from "@tanstack/react-query";
import { useTriaUser } from "../package/ui.common/src/contexts/tria-user-provider/useTriaUser";

export const useResolveTriaName = (chainName: string, address: string) => {
  const { getUserByAddress } = useTriaUser();

  return useQuery({
    queryKey: ["triaName", chainName, address],
    queryFn: () => getUserByAddress(address, chainName),
    enabled: !!chainName && !!address,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};
