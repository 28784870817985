export const mumbai = "MUMBAI";
export const polygon = "POLYGON";
export const ethereum = "ETH";
export const fuse = "FUSE";
export const fuseSpark = "FUSESPARK";
export const matChain: string = "MATCHAIN";
export const matChainTestnet: string = "MATCHAIN-TESTNET";
export const base = "BASE";
export const baseTestnet: string = "BASE-TESTNET";
export const saakuru = "SAAKURU";
export const oasys = "OASYS";
export const berachainBartio = "BERACHAIN-BARTIO";
export const xlayer = "XLAYER";
export const imx = "IMX";
export const eosg = "EOSG";

export const zkLink: string = "ZKLINK-NOVA";
export const zkLinkTestnet: string = "ZKLINK-NOVA-TESTNET";
export const bitlayerTestnet: string = "BITLAYER-TESTNET";
export const bitlayerMainnet: string = "BITLAYER-MAINNET";
export const neoxTestnet: string = "NEOX-TESTNET";
export const sepolia: string = "SEPOLIA";
export const polygonAmoy: string = "AMOY";
export const vanarTestnet: string = "VANGUARD-VANAR-TESTNET";
export const skaleTestnet: string = "SKALE-TESTNET";
export const solanaDevnet = "SOLANA-DEVNET";
export const injective = "INJECTIVE";
export const injectiveTestnet = "INJECTIVE-TESTNET";

export type ZKLINK = "ZKLINK-NOVA";
export type ZKLINK_TESTNET = "ZKLINK-NOVA-TESTNET";
export type BITLAYER_TESTNET = "BITLAYER-TESTNET";
export type BITLAYER_MAINNET = "BITLAYER-MAINNET";
export type NEOX_TESTNET = "NEOX-TESTNET";
export type SEPOLIA = "SEPOLIA";
export type AMOY = "AMOY";
export type VANGUARD_VANAR_TESTNET = "VANGUARD-VANAR-TESTNET";
export type SKALE_TESTNET = "SKALE-TESTNET";
export type MATCHAIN = "MATCHAIN";
export type MATCHAIN_TESTNET = "MATCHAIN-TESTNET";
export type BASE = "BASE";
export type BASE_TESTNET = "BASE-TESTNET";
export type SAAKURU = "SAAKURU";
export type OASYS = "OASYS";
export type BERACHAIN_BARTIO = "BERACHAIN-BARTIO";
export type XLAYER = "XLAYER";
export type IMX = "IMX";
export type EOSG = "EOSG";

export const TESTNET_CHAINS = [
  sepolia,
  "MUMBAI",
  "FUSESPARK",
  "MANTA-TESTNET",
  "METIS-TESTNET",
  "MANTLE-TESTNET",
  zkLinkTestnet,
  bitlayerTestnet,
  neoxTestnet,
  polygonAmoy,
  vanarTestnet,
  skaleTestnet,
  solanaDevnet,
  injectiveTestnet,
  matChainTestnet,
  baseTestnet,
  berachainBartio,
];

export const SUPPORTED_EVM_CHAINS = [
  "ETH",
  "POLYGON",
  "AVALANCHE",
  "ARBITRUM",
  "BINANCE",
  "OPTIMISM",
  "FANTOM",
  "FUSE",
  "FUSESPARK",
  "MUMBAI",
  "MANTA",
  "MANTA-TESTNET",
  "METIS",
  "METIS-TESTNET",
  "MANTLE",
  "MANTLE-TESTNET",
  zkLink,
  zkLinkTestnet,
  bitlayerTestnet,
  bitlayerMainnet,
  neoxTestnet,
  sepolia,
  polygonAmoy,
  vanarTestnet,
  skaleTestnet,
  matChain,
  matChainTestnet,
  base,
  baseTestnet,
  saakuru,
  oasys,
  berachainBartio,
  xlayer,
  imx,
  eosg,
];

export const GAS_ABS_ENABLED_CHAINS = [sepolia, "POLYGON"];

export const AA_SUPPORTED_EVM_CHAINS = [
  "ARBITRUM",
  "POLYGON",
  "BINANCE",
  "MUMBAI",
  sepolia,
  "OPTIMISM",
  "AVALANCHE",
  "MANTLE",
  "BASE",
];

export const ALLCHAINS = [
  "ETH",
  "POLYGON",
  "AVALANCHE",
  "ARBITRUM",
  "BINANCE",
  "OPTIMISM",
  "FANTOM",
  "FUSE",
  "FUSESPARK",
  "MUMBAI",
  sepolia,
  "MANTA",
  "MANTA-TESTNET",
  "METIS",
  "METIS-TESTNET",
  "MANTLE",
  "MANTLE-TESTNET",
  "SOLANA",
  solanaDevnet,
  zkLink,
  zkLinkTestnet,
  bitlayerTestnet,
  bitlayerMainnet,
  neoxTestnet,
  polygonAmoy,
  vanarTestnet,
  skaleTestnet,
  injective,
  injectiveTestnet,
  matChain,
  matChainTestnet,
  base,
  baseTestnet,
  saakuru,
  oasys,
  berachainBartio,
  xlayer,
  imx,
  eosg,
];

const baseLogoUrl = "https://static.tria.so/chain-logo-w";
const baseLogoUrlBlack = "https://static.tria.so/chain-logo-b";

export const networks = [
  {
    chainName: "ETH",
    logo: `${baseLogoUrl}/Ethereum.webp`,
    type: "mainnet",
  },
  {
    chainName: sepolia,
    logo: `${baseLogoUrl}/Ethereum.webp`,
    type: "testnet",
  },
  {
    chainName: "FANTOM",
    logo: `${baseLogoUrl}/Fantom.webp`,
    type: "mainnet",
  },
  {
    chainName: "POLYGON",
    logo: `${baseLogoUrl}/Polygon.svg`,
    type: "mainnet",
  },
  {
    chainName: polygonAmoy,
    logo: `${baseLogoUrl}/Polygon.svg`,
    type: "testnet",
  },
  {
    chainName: "AVALANCHE",
    logo: `${baseLogoUrl}/Avalanche.webp`,
    type: "mainnet",
  },
  {
    chainName: "OPTIMISM",
    logo: `${baseLogoUrl}/Optimism.webp`,
    type: "mainnet",
  },
  {
    chainName: "ARBITRUM",
    logo: `${baseLogoUrl}/Arbitrum.webp`,
    type: "mainnet",
  },
  {
    chainName: "FUSE",
    logo: `${baseLogoUrl}/Fuse.webp`,
    type: "mainnet",
  },
  {
    chainName: "FUSESPARK",
    logo: `${baseLogoUrl}/Fuse.webp`,
    type: "testnet",
  },
  {
    chainName: "MUMBAI",
    logo: `${baseLogoUrl}/Polygon.svg`,
    type: "testnet",
  },
  {
    chainName: "BINANCE",
    logo: `${baseLogoUrl}/Binance.svg`,
    type: "mainnet",
  },
  // {
  //     chainName: 'LINEA',
  //     logo: `${baseLogoUrl}/Linea.svg`,
  // },
  {
    chainName: "METIS",
    logo: `${baseLogoUrl}/Metis.webp`,
    type: "mainnet",
  },
  {
    chainName: "METIS-TESTNET",
    logo: `${baseLogoUrl}/Metis.webp`,
    type: "testnet",
  },
  {
    chainName: "MANTA",
    logo: `${baseLogoUrl}/Manta.webp`,
    type: "mainnet",
  },
  {
    chainName: "MANTA-TESTNET",
    logo: `${baseLogoUrl}/Manta.webp`,
    type: "testnet",
  },
  {
    chainName: "MANTLE",
    logo: `${baseLogoUrl}/Mantle.webp`,
    type: "mainnet",
  },
  {
    chainName: "MANTLE-TESTNET",
    logo: `${baseLogoUrl}/Mantle.webp`,
    type: "testnet",
  },
  {
    chainName: "SOLANA",
    logo: `${baseLogoUrl}/Solana.webp`,
    type: "mainnet",
  },
  {
    chainName: solanaDevnet,
    logo: `${baseLogoUrl}/Solana.webp`,
    type: "testnet",
  },
  {
    chainName: zkLink,
    logo: `${baseLogoUrl}/zklink.webp`,
    type: "mainnet",
  },
  {
    chainName: zkLinkTestnet,
    logo: `${baseLogoUrl}/zklink.webp`,
    type: "testnet",
  },
  {
    chainName: bitlayerTestnet,
    logo: `${baseLogoUrl}/bitlayer.webp`,
    type: "testnet",
  },
  {
    chainName: bitlayerMainnet,
    logo: `${baseLogoUrl}/bitlayer.webp`,
    type: "mainnet",
  },
  {
    chainName: neoxTestnet,
    logo: `${baseLogoUrl}/Neo.webp`,
    type: "testnet",
  },
  {
    chainName: vanarTestnet,
    logo: "https://vanguard.vanarchain.com/assets/vanry-logo.png", //`${baseLogoUrl}/Vanguard.webp`,
    type: "testnet",
  },
  {
    chainName: skaleTestnet,
    logo: `${baseLogoUrl}/skale.webp`,
    type: "testnet",
  },
  {
    chainName: injective,
    logo: `${baseLogoUrl}/Injective.webp`,
    type: "mainnet",
  },
  {
    chainName: injectiveTestnet,
    logo: `${baseLogoUrl}/Injective.webp`,
    type: "testnet",
  },
  {
    chainName: matChain,
    logo: `${baseLogoUrl}/Matchain.webp`,
    type: "mainnet",
  },
  {
    chainName: matChainTestnet,
    logo: `${baseLogoUrl}/MatchainTestnet.webp`,
    type: "testnet",
  },
  {
    chainName: base,
    logo: `${baseLogoUrl}/Base.webp`,
    type: "mainnet",
  },
  {
    chainName: baseTestnet,
    logo: `${baseLogoUrl}/Base.webp`,
    type: "testnet",
  },
  {
    chainName: saakuru,
    logo: `${baseLogoUrl}/Saakuru.webp`,
    type: "mainnet",
  },
  {
    chainName: oasys,
    logo: `${baseLogoUrl}/Oasys.webp`,
    type: "mainnet",
  },
  {
    chainName: berachainBartio,
    logo: `${baseLogoUrl}/Berachain.webp`,
    type: "testnet",
  },
  {
    chainName: xlayer,
    logo: `${baseLogoUrl}/XLayer.webp`,
    type: "mainnet",
  },
  {
    chainName: imx,
    logo: `${baseLogoUrl}/IMX.webp`,
    type: "mainnet",
  },
  {
    chainName: eosg,
    logo: `${baseLogoUrl}/EOSG.webp`,
    type: "mainnet",
  },
];

export const chainNameToLogo = {
  ETH: `${baseLogoUrl}/Ethereum.webp`,
  [sepolia]: `${baseLogoUrl}/Ethereum.webp`,
  FANTOM: `${baseLogoUrl}/Fantom.webp`,
  POLYGON: `${baseLogoUrl}/Polygon.webp`,
  [polygonAmoy]: `${baseLogoUrl}/Polygon.webp`,
  AVALANCHE: `${baseLogoUrl}/Avalanche.webp`,
  OPTIMISM: `${baseLogoUrl}/Optimism.webp`,
  ARBITRUM: `${baseLogoUrl}/Arbitrum.webp`,
  LINEA: `${baseLogoUrl}/Linea.webp`,
  MUMBAI: `${baseLogoUrl}/Polygon.webp`,
  FUSE: `${baseLogoUrl}/Fuse.webp`,
  FUSESPARK: `${baseLogoUrl}/Fuse.webp`,
  BINANCE: `${baseLogoUrl}/Binance.svg`,
  MANTA: `${baseLogoUrl}/Manta.webp`,
  "MANTA-TESTNET": `${baseLogoUrl}/Manta.webp`,
  METIS: `${baseLogoUrl}/Metis.webp`,
  "METIS-TESTNET": `${baseLogoUrl}/Metis.webp`,
  MANTLE: `${baseLogoUrl}/Mantle.webp`,
  "MANTLE-TESTNET": `${baseLogoUrl}/Mantle.webp`,
  SOLANA: `${baseLogoUrl}/Solana.webp`,
  [solanaDevnet]: `${baseLogoUrl}/Solana.webp`,
  [zkLink]: `${baseLogoUrl}/zklink.webp`,
  [zkLinkTestnet]: `${baseLogoUrl}/zklink.webp`,
  [bitlayerTestnet]: `${baseLogoUrl}/bitlayer.webp`,
  [bitlayerMainnet]: `${baseLogoUrl}/bitlayer.webp`,
  [neoxTestnet]: `${baseLogoUrl}/Neo.webp`,
  [vanarTestnet]: "https://vanguard.vanarchain.com/assets/vanry-logo.png", //`${baseLogoUrl}/Vanguard.webp`,
  [skaleTestnet]: `${baseLogoUrl}/skale.webp`,
  [injective]: `${baseLogoUrl}/Injective.webp`,
  [injectiveTestnet]: `${baseLogoUrl}/Injective.webp`,
  [matChain]: `${baseLogoUrl}/Matchain.webp`,
  [matChainTestnet]: `${baseLogoUrl}/MatchainTestnet.webp`,
  [base]: `${baseLogoUrl}/Base.webp`,
  [baseTestnet]: `${baseLogoUrl}/Base.webp`,
  [saakuru]: `${baseLogoUrl}/Saakuru.webp`,
  [oasys]: `${baseLogoUrl}/Oasys.webp`,
  [berachainBartio]: `${baseLogoUrl}/Berachain.webp`,
  [xlayer]: `${baseLogoUrl}/XLayer.webp`,
  [imx]: `${baseLogoUrl}/IMX.webp`,
  [eosg]: `${baseLogoUrl}/EOSG.webp`,
};
export const chainNameToLogoAverageColor: { [key: string]: string } = {
  ETH: "rgba(98,126,235,0.7)",
  POLYGON: "rgba(131,69,231,0.7)",
  SEPOLIA: "rgba(98,126,235,0.7)",
  FANTOM: "rgba(19,181,235,0.7)",
  AMOY: "rgba(131,69,231,0.7)",
  AVALANCHE: "rgba(232,65,67,0.7)",
  ARBITRUM: "rgba(17,170,255,0.7)",
  OPTIMISM: "rgba(255,5,32,0.7)",
  LINEA: "UNDETERMINED",
  MUMBAI: "rgba(131,69,231,0.7)",
  FUSESPARK: "rgba(180,249,185,0.7)",
  FUSE: "rgba(180,249,185,0.7)",
  MANTA: "rgba(98,126,235,0.7)",
  "MANTA-TESTNET": "rgba(98,126,235,0.7)",
  "METIS-TESTNET": "rgba(18,171,225,0.7)",
  METIS: "rgba(18,171,225,0.7)",
  SOLANA: "UNDETERMINED",
  "MANTLE-TESTNET": "UNDETERMINED",
  MANTLE: "UNDETERMINED",
  "BITLAYER-TESTNET": "rgba(226,110,26,0.7)",
  "ZKLINK-NOVA": "UNDETERMINED",
  "ZKLINK-NOVA-TESTNET": "UNDETERMINED",
  "NEOX-TESTNET": "rgba(1,229,155,0.7)",
  "VANGUARD-VANAR-TESTNET": "rgba(71, 51, 147,0.7)",
  BINANCE: "rgba(240,185,11,0.7)",
};

export const chainNameToLogoBlack = {
  ETH: `${baseLogoUrlBlack}/Ethereum.webp`,
  [sepolia]: `${baseLogoUrlBlack}/Ethereum.webp`,
  BINANCE: `${baseLogoUrlBlack}/binance.svg`,
  FANTOM: `${baseLogoUrlBlack}/Fantom.webp`,
  POLYGON: `${baseLogoUrlBlack}/Polygon.webp`,
  [polygonAmoy]: `${baseLogoUrlBlack}/Polygon.webp`,
  AVALANCHE: `${baseLogoUrlBlack}/Avalanche.webp`,
  OPTIMISM: `${baseLogoUrlBlack}/Optimism.webp`,
  ARBITRUM: `${baseLogoUrlBlack}/Arbitrum.webp`,
  LINEA: `${baseLogoUrlBlack}/Linea.webp`,
  MUMBAI: `${baseLogoUrlBlack}/Polygon.webp`,
  FUSE: `${baseLogoUrlBlack}/fuse.webp`,
  FUSESPARK: `${baseLogoUrlBlack}/fuse.webp`,
  MANTA: `${baseLogoUrlBlack}/Manta.webp`,
  "MANTA-TESTNET": `${baseLogoUrlBlack}/Manta.webp`,
  METIS: `${baseLogoUrlBlack}/Metis.webp`,
  "METIS-TESTNET": `${baseLogoUrlBlack}/Metis.webp`,
  MANTLE: `${baseLogoUrlBlack}/Mantle.webp`,
  "MANTLE-TESTNET": `${baseLogoUrlBlack}/Mantle.webp`,
  SOLANA: `${baseLogoUrlBlack}/Solana.webp`,
  [solanaDevnet]: `${baseLogoUrlBlack}/Solana.webp`,
  [zkLink]: `${baseLogoUrlBlack}/zklink.webp`,
  [zkLinkTestnet]: `${baseLogoUrlBlack}/zklink.webp`,
  [bitlayerTestnet]: `${baseLogoUrlBlack}/bitlayer.webp`,
  [bitlayerMainnet]: `${baseLogoUrlBlack}/bitlayer.webp`,
  [neoxTestnet]: `${baseLogoUrlBlack}/Neo.png`,
  [vanarTestnet]: "https://vanguard.vanarchain.com/assets/vanry-logo.png", //`${baseLogoUrlBlack}/Vanguard.png`,
  [skaleTestnet]: `${baseLogoUrlBlack}/skale.webp`,
  [injective]: `${baseLogoUrlBlack}/Injective.webp`,
  [injectiveTestnet]: `${baseLogoUrlBlack}/Injective.webp`,
  [matChain]: `${baseLogoUrl}/Matchain.webp`,
  [matChainTestnet]: `${baseLogoUrlBlack}/MatchainTestnet.webp`,
  [base]: `${baseLogoUrlBlack}/Base.webp`,
  [baseTestnet]: `${baseLogoUrlBlack}/Base.webp`,
  [saakuru]: `${baseLogoUrlBlack}/Saakuru.webp`,
  [oasys]: `${baseLogoUrlBlack}/Oasys.webp`,
  [berachainBartio]: `${baseLogoUrlBlack}/Berachain.webp`,
  [xlayer]: `${baseLogoUrlBlack}/XLayer.webp`,
  [imx]: `${baseLogoUrlBlack}/IMX.webp`,
  [eosg]: `${baseLogoUrlBlack}/EOSG.webp`,
};

export const chainNameToLogoBlackAverageColor = {
  FANTOM: [
    { color: "rgb(255,255,255)", count: 25004 },
    { color: "rgb(132,132,132)", count: 3219 },
    { color: "rgb(143,143,143)", count: 2392 },
    { color: "rgb(114,114,114)", count: 2283 },
    { color: "rgb(254,254,254)", count: 2250 },
  ],

  POLYGON: [
    { color: "rgb(255,255,255)", count: 26414 },
    { color: "rgb(132,132,132)", count: 3390 },
    { color: "rgb(128,128,128)", count: 2783 },
    { color: "rgb(125,125,125)", count: 2448 },
    { color: "rgb(118,118,118)", count: 2387 },
  ],

  ETH: [
    { color: "rgb(255,255,255)", count: 23340 },
    { color: "rgb(132,132,132)", count: 2559 },
    { color: "rgb(111,111,111)", count: 2372 },
    { color: "rgb(143,143,143)", count: 2341 },
    { color: "rgb(210,210,210)", count: 2325 },
  ],

  SEPOLIA: [
    { color: "rgb(255,255,255)", count: 23340 },
    { color: "rgb(132,132,132)", count: 2559 },
    { color: "rgb(111,111,111)", count: 2372 },
    { color: "rgb(143,143,143)", count: 2341 },
    { color: "rgb(210,210,210)", count: 2325 },
  ],

  AMOY: [
    { color: "rgb(255,255,255)", count: 26414 },
    { color: "rgb(132,132,132)", count: 3390 },
    { color: "rgb(128,128,128)", count: 2783 },
    { color: "rgb(125,125,125)", count: 2448 },
    { color: "rgb(118,118,118)", count: 2387 },
  ],

  ARBITRUM: [
    { color: "rgb(255,255,255)", count: 37899 },
    { color: "rgb(143,143,143)", count: 2422 },
    { color: "rgb(111,111,111)", count: 2106 },
    { color: "rgb(105,105,105)", count: 1930 },
    { color: "rgb(254,254,254)", count: 1913 },
  ],

  OPTIMISM: [
    { color: "rgb(255,255,255)", count: 27023 },
    { color: "rgb(132,132,132)", count: 3031 },
    { color: "rgb(111,111,111)", count: 2578 },
    { color: "rgb(125,125,125)", count: 2530 },
    { color: "rgb(118,118,118)", count: 2349 },
  ],

  LINEA: [
    { color: "rgb(255,255,255)", count: 28540 },
    { color: "rgb(132,132,132)", count: 3517 },
    { color: "rgb(128,128,128)", count: 3200 },
    { color: "rgb(118,118,118)", count: 2815 },
    { color: "rgb(143,143,143)", count: 2740 },
  ],

  AVALANCHE: [
    { color: "rgb(255,255,255)", count: 28225 },
    { color: "rgb(132,132,132)", count: 3609 },
    { color: "rgb(111,111,111)", count: 2934 },
    { color: "rgb(143,143,143)", count: 2876 },
    { color: "rgb(128,128,128)", count: 2668 },
  ],

  MUMBAI: [
    { color: "rgb(255,255,255)", count: 26414 },
    { color: "rgb(132,132,132)", count: 3390 },
    { color: "rgb(128,128,128)", count: 2783 },
    { color: "rgb(125,125,125)", count: 2448 },
    { color: "rgb(118,118,118)", count: 2387 },
  ],

  FUSE: [
    { color: "rgb(255,255,255)", count: 34461 },
    { color: "rgb(125,125,125)", count: 2518 },
    { color: "rgb(128,128,128)", count: 2480 },
    { color: "rgb(143,143,143)", count: 2141 },
    { color: "rgb(254,254,254)", count: 2017 },
  ],

  FUSESPARK: [
    { color: "rgb(255,255,255)", count: 34461 },
    { color: "rgb(125,125,125)", count: 2518 },
    { color: "rgb(128,128,128)", count: 2480 },
    { color: "rgb(143,143,143)", count: 2141 },
    { color: "rgb(254,254,254)", count: 2017 },
  ],

  MANTA: [
    { color: "rgb(0,0,0)", count: 35607 },
    { color: "rgb(16,16,16)", count: 27650 },
    { color: "rgb(17,17,17)", count: 5711 },
    { color: "rgb(128,16,16)", count: 898 },
    { color: "rgb(16,128,16)", count: 864 },
  ],

  "MANTA-TESTNET": [
    { color: "rgb(0,0,0)", count: 35607 },
    { color: "rgb(16,16,16)", count: 27650 },
    { color: "rgb(17,17,17)", count: 5711 },
    { color: "rgb(128,16,16)", count: 898 },
    { color: "rgb(16,128,16)", count: 864 },
  ],

  METIS: [
    { color: "rgb(0,0,0)", count: 35638 },
    { color: "rgb(16,16,16)", count: 24682 },
    { color: "rgb(17,17,17)", count: 4693 },
    { color: "rgb(124,16,16)", count: 764 },
    { color: "rgb(16,16,157)", count: 758 },
  ],

  "METIS-TESTNET": [
    { color: "rgb(0,0,0)", count: 35638 },
    { color: "rgb(16,16,16)", count: 24682 },
    { color: "rgb(17,17,17)", count: 4693 },
    { color: "rgb(124,16,16)", count: 764 },
    { color: "rgb(16,16,157)", count: 758 },
  ],

  SOLANA: [
    { color: "rgb(255,255,255)", count: 32241 },
    { color: "rgb(132,132,132)", count: 3455 },
    { color: "rgb(254,254,254)", count: 2440 },
    { color: "rgb(143,143,143)", count: 2295 },
    { color: "rgb(114,114,114)", count: 2228 },
  ],

  "MANTLE-TESTNET": [
    { color: "rgb(0,0,0)", count: 35380 },
    { color: "rgb(16,16,16)", count: 21481 },
    { color: "rgb(17,17,17)", count: 6742 },
    { color: "rgb(242,242,242)", count: 1969 },
    { color: "rgb(15,15,15)", count: 1408 },
  ],

  MANTLE: [
    { color: "rgb(0,0,0)", count: 35380 },
    { color: "rgb(16,16,16)", count: 21481 },
    { color: "rgb(17,17,17)", count: 6742 },
    { color: "rgb(242,242,242)", count: 1969 },
    { color: "rgb(15,15,15)", count: 1408 },
  ],

  "ZKLINK-NOVA-TESTNET": [
    { color: "rgb(255,255,255)", count: 22586 },
    { color: "rgb(132,132,132)", count: 3447 },
    { color: "rgb(118,118,118)", count: 3122 },
    { color: "rgb(111,111,111)", count: 2516 },
    { color: "rgb(125,125,125)", count: 2497 },
  ],

  "ZKLINK-NOVA": [
    { color: "rgb(255,255,255)", count: 22586 },
    { color: "rgb(132,132,132)", count: 3447 },
    { color: "rgb(118,118,118)", count: 3122 },
    { color: "rgb(111,111,111)", count: 2516 },
    { color: "rgb(125,125,125)", count: 2497 },
  ],

  "BITLAYER-TESTNET": [
    { color: "rgb(255,255,255)", count: 32861 },
    { color: "rgb(111,111,111)", count: 2685 },
    { color: "rgb(132,132,132)", count: 2623 },
    { color: "rgb(143,143,143)", count: 2323 },
    { color: "rgb(115,115,115)", count: 1953 },
  ],

  "NEOX-TESTNET": [
    { color: "rgb(255,255,255)", count: 39661 },
    { color: "rgb(132,132,132)", count: 2785 },
    { color: "rgb(143,143,143)", count: 2494 },
    { color: "rgb(111,111,111)", count: 1972 },
    { color: "rgb(140,140,140)", count: 1966 },
  ],

  "VANGUARD-VANAR-TESTNET": [
    { color: "rgb(0,0,0)", count: 127157 },
    { color: "rgb(255,255,255)", count: 3071 },
    { color: "rgb(255,255,0)", count: 3057 },
    { color: "rgb(0,255,255)", count: 2993 },
    { color: "rgb(255,0,255)", count: 2892 },
  ],

  BINANCE: [
    { color: "rgb(0,0,0)", count: 2614 },
    { color: "rgb(255,255,255)", count: 1676 },
    { color: "rgb(15,15,15)", count: 974 },
    { color: "rgb(16,16,16)", count: 855 },
    { color: "rgb(17,17,17)", count: 56 },
  ],
};
