import React from "react";

export const ChainLogo = ({
  logo,
  chainName,
}: {
  logo?: string;
  chainName: string;
}) => {
  if (logo) {
    return (
      <img
        src={logo}
        alt="chain"
        className="w-[2.4vh] h-[2.4vh] rounded-[0.5vh]"
      />
    );
  }

  return (
    <div className="w-[2.4vh] h-[2.4vh] rounded-[0.5vh] flex items-center justify-center text-[#FFFFFF] bg-purple-500 font-[600] text-[1.2vh]">
      {chainName?.charAt(0)}
    </div>
  );
};
