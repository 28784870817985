import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useUserAddresses } from "../../../../../hooks/useUserAddresses";
import { formatAddress } from "../../../../rx.core";
import { ArrowButton } from "../Buttons";
import { ChainLogo } from "../icons/ChainLogo";
interface Props {
  logo: string;
  chainName: string;
  eoavalueInUsd: number;
  aavalueInUsd: number;
  setShowHistory: Dispatch<SetStateAction<"All" | "EOA" | "AA">>;
  setCurrentAddress: Dispatch<SetStateAction<string>>;
}

// Define the type for dropdown options
interface DropdownOption {
  id: number;
  label: string;
  type: "All" | "AA" | "EOA";
  value: number;
}

const AddressDropdown: React.FC<Props> = ({
  logo,
  chainName,
  eoavalueInUsd,
  aavalueInUsd,
  setShowHistory,
  setCurrentAddress,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    null
  );

  const { aaAddress, eoaAddress } = useUserAddresses({ chainName });

  // Dropdown options
  const options: DropdownOption[] = useMemo(() => {
    const opts: DropdownOption[] = [
      { id: 2, label: eoaAddress, type: "EOA", value: eoavalueInUsd },
    ];
    if (aaAddress) {
      opts.unshift({ id: 1, label: "All", type: "All", value: 0 });
      opts.push({
        id: 3,
        label: aaAddress,
        type: "AA",
        value: aavalueInUsd,
      });
    }
    return opts;
  }, [aaAddress, aavalueInUsd, eoaAddress, eoavalueInUsd]);

  useEffect(() => {
    setShowHistory(options[0].type);
    setSelectedOption(options[0]);
  }, [options, setShowHistory]);

  // Handle selecting an option
  const handleSelectOption = (optionLabel: DropdownOption) => {
    setSelectedOption(optionLabel);
    setIsOpen(false); // Close the dropdown after selection
    console.log(`Selected: ${optionLabel}`);
    setShowHistory(optionLabel?.type);
    optionLabel?.type === "All"
      ? aavalueInUsd > 0
        ? setCurrentAddress(aaAddress)
        : setCurrentAddress(eoaAddress)
      : optionLabel?.type === "AA"
      ? setCurrentAddress(aaAddress)
      : setCurrentAddress(eoaAddress);
  };

  return (
    <>
      {/* Background Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm z-0"
          onClick={() => options.length > 1 && setIsOpen(false)}
        ></div>
      )}

      {/* Dropdown */}
      <div className="relative z-10">
        <button
          onClick={() => options.length > 1 && setIsOpen(!isOpen)}
          className="px-[2vh] py-[0.5vh] border-[0.1vh] dark:bg-outliningDark border-outlining dark:border-outliningDark text-fontLightColor dark:text-fontLightColorDark focus:outline-none rounded-[2vh] flex gap-[0.5vh] items-center justify-between text-[1.8vh] font-[600]leading-[120%]"
        >
          {selectedOption?.label === "All" ? (
            <div>{selectedOption?.label || "Select an Option"}</div>
          ) : (
            <div className="flex gap-[0.8vh] items-center justify-center">
              <ChainLogo logo={logo} chainName={chainName} />
              <p>
                {formatAddress(selectedOption?.label ?? "") ||
                  "Select an Option"}
              </p>
              {selectedOption?.type?.toUpperCase() === "AA" && (
                <img
                  src="./images/aaverifiedbatch.svg"
                  alt="aa"
                  className="w-[2.8vh] h-[2.8vh]"
                />
              )}
            </div>
          )}
          {options.length > 1 && <ArrowButton />}
        </button>
        {isOpen && (
          <ul className="fixed top-[34vh] left-1/2 transform -translate-x-1/2 w-[38vh] bg-primaryColor dark:bg-primaryDarkColor shadow-md rounded-[2vh] px-[1vh] py-[2vh] text-fontPrimaryColor dark:text-fontPrimaryDarkColor flex flex-col gap-[0.5vh] border-[0.1vh] dark:border-outliningDark border-outlining">
            {options.map((option) =>
              option?.label === "All" ? (
                <li
                  key={option.id}
                  onClick={() => handleSelectOption(option)}
                  className={`p-[1.4vh] gap-[1vh] rounded-[1vh] ${
                    selectedOption?.label === option?.label
                      ? "border-[0.1vh] dark:border-outliningDark border-outlining bg-hoverColor dark:bg-hoverDarkColor"
                      : ""
                  } text-[2vh] hover:dark:bg-hoverDarkColor cursor-pointer`}
                >
                  {option.label}
                </li>
              ) : (
                <li
                  key={option.id}
                  onClick={() => handleSelectOption(option)}
                  className={`p-[1.4vh] flex items-center justify-between gap-[1vh] rounded-[1vh] ${
                    selectedOption?.label === option?.label
                      ? "border-[0.1vh] dark:border-outliningDark border-outlining bg-hoverColor dark:bg-hoverDarkColor"
                      : ""
                  } text-[2vh] hover:dark:bg-hoverDarkColor cursor-pointer`}
                >
                  <div className="flex flex-col">
                    <p className="text-[2vh] font-semibold leading-normal text-fontPrimaryColor flex dark:text-fontPrimaryDarkColor">
                      {chainName}
                      {option?.type === "AA" && (
                        <img
                          src="./images/aaverifiedbatch.svg"
                          alt="badge"
                          className="w-[3vh] h-[3vh]"
                        />
                      )}
                    </p>
                    <p className="text-[1.67vh] font-semibold text-fontLightColor flex dark:text-fontLightColorDark ">
                      {" "}
                      ({formatAddress(option.label)})
                    </p>
                  </div>
                  <p className="text-[1.67vh] font-semibold text-fontLightColor flex dark:text-fontLightColorDark ">
                    ${option?.value?.toFixed(2)}
                  </p>
                </li>
              )
            )}
          </ul>
        )}
      </div>
    </>
  );
};

export default AddressDropdown;
