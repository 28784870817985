import { lifiUrl } from "@tria-sdk/constants";
import axios from "axios";

export const getToken = async (chain: number, token: string) => {
  const result = await axios.get(`${lifiUrl}/token`, {
    params: {
      chain,
      token,
    },
  });
  return result.data;
};
