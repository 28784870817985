import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import storage from "redux-persist/lib/storage";
import {
  counterSlice,
  balanceSlice,
  toastSlice,
  networkSelectSlice,
  userSlice,
  routeSlice,
  nftDetailSlice,
  sendTokenSlice,
  appSlice,
  transactionSlice,
  assetSlice,
  sendNftSlice,
  staticItemsSlice,
  logSlice,
  cacheSlice,
  userPrefSlice,
} from "./features";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["Route", "toast"],
};

const rootReducer = combineReducers({
  counter: counterSlice.reducer,
  balance: balanceSlice.reducer,
  toast: toastSlice.reducer,
  userpref: userPrefSlice.reducer,
  networkSelect: networkSelectSlice.reducer,
  User: userSlice.reducer,
  Route: routeSlice.reducer,
  Asset: assetSlice.reducer,
  NftDetail: nftDetailSlice.reducer,
  SendToken: sendTokenSlice.reducer,
  AppState: appSlice.reducer,
  TransactionState: transactionSlice.reducer,
  SendNftState: sendNftSlice.reducer,
  Constants: staticItemsSlice.reducer,
  ApiLogs: logSlice.reducer,
  Caches: cacheSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
