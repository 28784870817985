import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NFTMetadata, RootState } from "../package/rx.core";
import {
  updateRefreshLoad,
  updateUserNfts,
  updateUserNftsLastFetch,
} from "../package/rx.core/src/redux/features";
import { useReload } from "../package/ui.common/src/contexts/reload/ReloadContext";
import { useTriaUser } from "../package/ui.common/src/contexts/tria-user-provider/useTriaUser";
import { useTriaName } from "./useTriaName";
import { useUserPrefs } from "./useFetchUserPrefs";
import { CustomChainData, CustomNftData } from "@tria-sdk/core";

const isValidChain = (chain: CustomChainData): boolean => {
  return (
    chain &&
    typeof chain === "object" &&
    typeof chain.chainId !== "undefined" &&
    chain.chainId !== null
  );
};

async function fetchNFTMetadata(metadataUrl: string): Promise<NFTMetadata> {
  const response = await fetch(metadataUrl);
  const metadata = await response.json();
  const { name, description, image, attributes } = metadata;
  return {
    name,
    description,
    image,
    attributes,
  };
}

// Helper function to validate token object
const isValidNft = (token: CustomNftData): boolean => {
  return (
    token &&
    typeof token === "object" &&
    typeof token.chainId !== "undefined" &&
    token.chainId !== null &&
    token.collectionAddress !== null
  );
};

export function useFetchNfts() {
  const dispatch = useDispatch();
  const { reload } = useReload();
  const { getNfts, getNFTDetails } = useTriaUser();
  const { data: userPrefs } = useUserPrefs();

  const [loading, setLoading] = useState<boolean>(false);
  const lastFetchTime = useSelector(
    (state: RootState) => state.User.userNftsLastFetch
  );
  const nftsDataExists = useSelector(
    (state: RootState) => state.User.userNfts.length > 0
  );
  const userInactive = useSelector(
    (state: RootState) => state.User.isUserInactive
  );

  const refreshLoading = useSelector(
    (store: RootState) => store?.AppState?.AppCurrentState?.refreshLoading
  );
  const { triaName } = useTriaName();

  const fetchNfts = async () => {
    const validChains = (userPrefs?.customChains || []).filter(isValidChain);
    const validTokens = (userPrefs?.customNfts || []).filter(isValidNft);
    const now = new Date();
    if (
      !lastFetchTime ||
      (now.getTime() - new Date(lastFetchTime).getTime() >= 10000 &&
        !userInactive) ||
      reload
    ) {
      try {
        if (!nftsDataExists && !lastFetchTime) {
          setLoading(true);
        }
        if (triaName) {
          const [normalNfts, customNfts] = await Promise.all([
            getNfts(undefined, undefined, "", [], triaName),
            Promise.all(
              validChains.flatMap((chain) =>
                validTokens
                  .filter(
                    (token) => parseFloat(token.chainId) === chain.chainId
                  )
                  .map(async (token) => {
                    try {
                      const detail = await getNFTDetails(
                        undefined,
                        token?.collectionAddress,
                        token?.tokenId,
                        triaName,
                        chain,
                        "true",
                        token?.type === "ERC721" ? "true" : "false",
                        true
                      );

                      // Ensure we return null or a properly typed custom asset
                      if (!detail) return null;

                      return {
                        ...detail,
                        chainName: chain.chainName,
                        isCustomToken: true as const,
                        type: token.type,
                      };
                    } catch (error) {
                      console.warn(
                        `Failed to fetch details for token ${token.collectionAddress} on chain ${chain.chainId}:`,
                        error
                      );
                      return null;
                    }
                  })
              )
            ).then((results) =>
              results.filter((result): result is any => result !== null)
            ),
            validTokens?.map,
          ]);
          customNfts.map((nft) => {
            console.log("custom nft", nft);

            normalNfts.push(nft);
          });
          // const items2 = await etNftsDetails();
          dispatch(updateUserNfts(normalNfts));
          dispatch(updateUserNftsLastFetch(new Date().toISOString()));
        }
      } catch (error: any) {
        console.error(error);
      } finally {
        setLoading(false);
        dispatch(updateRefreshLoad(false));
      }
    }
  };

  useEffect(() => {
    fetchNfts();
    const intervalId = setInterval(fetchNfts, 10000);
    return () => clearInterval(intervalId);
  }, [triaName, dispatch, lastFetchTime, userInactive]);

  useEffect(() => {
    if (reload) {
      fetchNfts();
      setLoading(true);
    }
  }, [reload]);
  useEffect(() => {
    if (!refreshLoading && loading) {
      dispatch(updateRefreshLoad(true));
    }
  }, [refreshLoading]);
  return { loading };
}
